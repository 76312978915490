<template>
  <v-container fluid class="full">
    <v-row class="fill">
      <v-col cols="9">
        <div ref="map-root" style="width: 100%; height: 100%"></div>
        <div id="popup" class="ol-popup">
          <a href="#" id="popup-closer" class="ol-popup-closer"></a>
          <div id="popup-content"></div>
        </div>
      </v-col>
      <v-col cols="3">
        <div v-if="Object.keys(featureAttributes).length">
          <b>ID: </b> {{ featureAttributes.ogcFid }}<br />
          <b>TVWA Priority Rank: </b> {{ featureAttributes.tvwaPriorityRank
          }}<br />
          <b>Waterway:</b> {{ featureAttributes.riverName }}<br />
          <b>Road:</b> {{ featureAttributes.roadName }}<br />
          <ul>
            <li><b>Class:</b> {{ featureAttributes.roadClass }}</li>
            <li><b>Surface:</b> {{ featureAttributes.roadSurface }}</li>
            <li><b>Ownership:</b> {{ featureAttributes.public }}</li>
            <li><b>Maintenance:</b> {{ featureAttributes.maintenance }}</li>
          </ul>
          <b>Crossing:</b><br />
          <ul>
            <li><b>Type:</b> {{ featureAttributes.crossingType }}</li>
            <li><b>Culvert IDs:</b> {{ featureAttributes.culvertId }}</li>
          </ul>
          <b>AWC Species Information:</b>
          <ul
            v-if="
              featureAttributes.awcSpecies &&
              featureAttributes.awcSpecies.length
            "
          >
            <li>{{ featureAttributes.awcSpecies }}</li>
          </ul>
          <b>Culvert Fish Passage:</b> {{ featureAttributes.culvertRatingCode
          }}<br />
          <b>TDML:</b> {{ featureAttributes.tdmlCriteriaOfConcern }}<br />
        </div>
        <div v-else>Hover over a crossing to view attributes</div>
      </v-col>
    </v-row>
    <!-- <v-row class="bottom"> -->
    <!--   <v-col> -->
    <!--     <v-btn color="accent" @click="getFeatures"> -->
    <!--       {{ getFeaturesBtnLabel }} -->
    <!--       <v-progress-circular -->
    <!--         v-if="loadingData" -->
    <!--         indeterminate -->
    <!--         color="primary" -->
    <!--         :size="30" -->
    <!--         class="ml-3" -->
    <!--       ></v-progress-circular> -->
    <!--     </v-btn> -->
    <!--   </v-col> -->
    <!-- </v-row> -->
  </v-container>
</template>

<script>
import Map from "ol/Map";
import View from "ol/View";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ";
import { Circle, RegularShape, Stroke, Fill, Text, Style } from "ol/style";
import VectorTileSource from "ol/source/VectorTile";
import VectorTileLayer from "ol/layer/VectorTile";
import MVT from "ol/format/MVT";
// import GeoJSON from "ol/format/GeoJSON";
// import { bbox } from "ol/loadingstrategy";
import Overlay from "ol/Overlay.js";
import proj4 from "proj4";
import { transform } from "ol/proj";
import { register } from "ol/proj/proj4";

import LayerSwitcher from "ol-layerswitcher";

import "ol/ol.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";

import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;

import localforage from "localforage";

proj4.defs("EPSG:32606", "+proj=utm +zone=6 +datum=WGS84 +units=m +no_defs");
register(proj4);

const baseURL = process.env.VUE_APP_MVT_URL;

export default {
  name: "CrossingsMap",
  components: {},
  props: {},
  data: () => ({
    maxResolutionText: 5,
    loadingData: false,
    map: null,
    overlay: null,
    popupContent: null,
    layerSwitcher: null,
    surface: null,
    chenaWatershed: null,
    flowline: null,
    awcStream: null,
    affi: null,
    fnsbRoads: null,
    fnsbRoadsSource: null,
    selectedFeature: {},
    selectionLayer: null,
    featureAttributes: {},
    pointStyle: new Circle({
      radius: 5,
      fill: new Fill({ color: "rgba(128, 128, 196, 0.7)" }),
      stroke: new Stroke({ color: "rgba(64, 64, 128, 1.0)", width: 2 }),
    }),
    smallPointStyle: new Circle({
      radius: 3,
      fill: new Fill({ color: "rgba(128, 128, 196, 0.7)" }),
      stroke: new Stroke({ color: "rgba(64, 64, 128, 1.0)", width: 2 }),
    }),
    riverHighlightStyle: new Style({
      image: new RegularShape({
        stroke: new Stroke({ width: 2, color: "#33000099" }),
        fill: new Fill({ color: "#ff000099" }),
        points: 5,
        radius: 14,
        radius2: 6,
        angle: 0,
      }),
    }),
    streamHighlightStyle: new Style({
      image: new RegularShape({
        stroke: new Stroke({ width: 2, color: "#33000099" }),
        fill: new Fill({ color: "#ff000099" }),
        points: 30,
        radius: 8,
        radius2: 8,
        angle: 0,
      }),
    }),
  }),
  filters: {
    toFixed: function (v, f) {
      return parseFloat(v).toFixed(f);
    },
  },
  computed: {
    extent: function () {
      return this.map.getView().calculateExtent(this.map.getSize());
    },
    visibleFeatures: function () {
      let features = [];
      if (this.eagleNests) {
        this.eagleNests.forEachFeatureInExtent(this.extent, function (feature) {
          let coordinates = feature.getGeometry().getCoordinates();
          let coordinatesWGS84 = transform(
            coordinates,
            "EPSG:3857",
            "EPSG:4326"
          );
          let coordinates32606 = transform(
            coordinates,
            "EPSG:3857",
            "EPSG:32606"
          );
          let featureObject = {
            nest_id: feature.get("nest_id"),
            build_species: feature.get("build_species"),
            latitude: coordinatesWGS84[1],
            longitude: coordinatesWGS84[0],
            x_32606: coordinates32606[0],
            y_32606: coordinates32606[1],
          };
          features.push(featureObject);
        });
      }

      // only unique nests
      // one-liner:
      return features.filter(
        (e, i) => features.findIndex((a) => a.nest_id === e.nest_id) === i
      );
      // theoretically faster:
      // let unique = {};
      // for (let f of features) {
      //   unique[f.nest_id] = f;
      // }
      // let uniqueFeatures = [];
      // for (let k in unique) {
      //   uniqueFeatures.push(unique[k]);
      // }
      // return uniqueFeatures;
    },
    getFeaturesBtnLabel: function () {
      let featureCount = this.visibleFeatures.length;
      if (featureCount === 1) {
        return `Get ${featureCount} feature`;
      } else {
        return `Get ${featureCount} features`;
      }
    },
  },
  created() {
    localforage.config({
      name: "chena-river-crossing",
      version: 1,
    });
  },
  mounted: async function () {
    this.popupElement = document.getElementById("popup");
    this.popupContent = document.getElementById("popup-content");
    this.popupCloser = document.getElementById("popup-closer");
    this.popupCloser.onclick = () => {
      this.overlay.setPosition(undefined);
      this.popupCloser.blur();

      return false;
    };

    this.surface = new VectorTileLayer({
      title: "Surface",
      source: new VectorTileSource({
        format: new MVT(),
        url: `${baseURL}/gis.surface/{z}/{x}/{y}.pbf`,
      }),
      style: (f) => {
        const properties = f.getProperties();
        if (properties.veg_cover === "pavement - direct") {
          return new Style({
            stroke: new Stroke({ width: 1, color: "#670000ff" }),
            fill: new Fill({ color: "#670000aa" }),
          });
        } else if (properties.veg_cover === "pavement - indirect") {
          return new Style({
            stroke: new Stroke({ width: 1, color: "#C00000ff" }),
            fill: new Fill({ color: "#C00000aa" }),
          });
        } else if (properties.veg_cover === "gravel") {
          return new Style({
            stroke: new Stroke({ width: 1, color: "#7B7B7Bff" }),
            fill: new Fill({ color: "#7B7B7Baa" }),
          });
        } else if (properties.veg_cover === "riprap") {
          return new Style({
            stroke: new Stroke({ width: 1, color: "#AFAFAFff" }),
            fill: new Fill({ color: "#AFAFAFaa" }),
          });
        } else if (properties.veg_cover === "sparse vegetation") {
          return new Style({
            stroke: new Stroke({ width: 1, color: "#7FFF7Fff" }),
            fill: new Fill({ color: "#7FFF7Faa" }),
          });
        } else if (properties.veg_cover === "light vegetation") {
          return new Style({
            stroke: new Stroke({ width: 1, color: "#00C400ff" }),
            fill: new Fill({ color: "#00C400aa" }),
          });
        } else if (properties.veg_cover === "moderate vegetation") {
          return new Style({
            stroke: new Stroke({ width: 1, color: "#007E00ff" }),
            fill: new Fill({ color: "#007E00aa" }),
          });
        } else if (properties.veg_cover === "heavy vegetation") {
          return new Style({
            stroke: new Stroke({ width: 1, color: "#004C00ff" }),
            fill: new Fill({ color: "#004C00aa" }),
          });
        } else {
          return new Style({
            stroke: new Stroke({ width: 1, color: "#FF7F00ff" }),
            fill: new Fill({ color: "#FF7F00aa" }),
          });
        }
      },
    });

    this.chenaWatershed = new VectorTileLayer({
      title: "Chena River Watershed",
      source: new VectorTileSource({
        format: new MVT(),
        url: `${baseURL}/gis.chena_river_watershed/{z}/{x}/{y}.pbf`,
      }),
      style: new Style({
        stroke: new Stroke({ width: 4, color: "#006600aa" }),
        fill: new Fill({ color: "#00990000" }),
      }),
    });
    this.fnsbRoadsSource = new VectorTileSource({
      format: new MVT(),
      url: `${baseURL}/gis.fnsb_road/{z}/{x}/{y}.pbf`,
    });
    this.fnsbRoads = new VectorTileLayer({
      title: "FNSB Roads",
      source: this.fnsbRoadsSource,
      style: this.roadStyleFunction,
    });
    this.flowline = new VectorTileLayer({
      title: "Rivers",
      source: new VectorTileSource({
        format: new MVT(),
        url: `${baseURL}/gis.flowline/{z}/{x}/{y}.pbf`,
      }),
      style: new Style({
        stroke: new Stroke({ width: 2, color: "#0000ff99" }),
        fill: new Fill({ color: "#0000ff33" }),
      }),
    });
    this.awcStream = new VectorTileLayer({
      title: "AWC Streams",
      visible: false,
      source: new VectorTileSource({
        format: new MVT(),
        url: `${baseURL}/gis.awc_stream_view/{z}/{x}/{y}.pbf`,
      }),
      style: new Style({
        stroke: new Stroke({ width: 2, color: "#9900ff99" }),
        fill: new Fill({ color: "#9900ff33" }),
      }),
    });
    this.affi = new VectorTileLayer({
      title: "AFFI Points",
      visible: false,
      source: new VectorTileSource({
        format: new MVT({
          idProperty: "ogc_fid",
        }),
        url: `${baseURL}/gis.affi/{z}/{x}/{y}.pbf`,
      }),
      style: new Style({
        image: new RegularShape({
          stroke: new Stroke({ width: 2, color: "#33110099" }),
          fill: new Fill({ color: "#ff990099" }),
          points: 3,
          radius: 6,
          rotation: Math.PI / 4,
          angle: 60,
        }),
      }),
    });
    this.mainCrossings = new VectorTileLayer({
      title: "Crossings",
      source: new VectorTileSource({
        format: new MVT({
          idProperty: "ogc_fid",
        }),
        url: `${baseURL}/gis.crossing_all_attributes/{z}/{x}/{y}.pbf`,
      }),
      style: (f) => {
        const properties = f.getProperties();
        if (properties.tvwa_row_number) {
          return new Style({
            image: new RegularShape({
              stroke: new Stroke({ width: 2, color: "#33000099" }),
              fill: new Fill({ color: "#ff000099" }),
              points: 5,
              radius: 10,
              radius2: 4,
              angle: 0,
            }),
          });
        } else if (properties.main_river === "Main River") {
          return new Style({
            image: new Circle({
              stroke: new Stroke({ width: 2, color: "#33330099" }),
              fill: new Fill({ color: "#ffff0099" }),
              radius: 5,
            }),
          });
        } else {
          return new Style({
            image: new Circle({
              stroke: new Stroke({ width: 2, color: "#33330099" }),
              fill: new Fill({ color: "#ffff0099" }),
              radius: 3,
            }),
          });
        }
      },
    });
    this.overlay = new Overlay({
      element: this.popupElement,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });
    this.map = new Map({
      target: this.$refs["map-root"],
      overlays: [this.overlay],
      layers: [
        new LayerGroup({
          title: "Base maps",
          layers: [
            new TileLayer({
              title: "ESRI World Imagery",
              type: "base",
              visible: false,
              source: new XYZ({
                url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                attributions:
                  'Tiles © <a href="https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/">ArcGIS</a>',
              }),
            }),
            new TileLayer({
              title: "DNR Satellite",
              type: "base",
              visible: false,
              source: new XYZ({
                url: "https://geoportal.alaska.gov/arcgis/rest/services/ahri_2020_rgb_cache/MapServer/tile/{z}/{y}/{x}",
                attributions:
                  "Maxar Products. Alaska High Resolution Imagery © 2020 Maxar Technologies Inc.",
              }),
            }),
            new TileLayer({
              title: "OpenStreetMap",
              type: "base",
              visible: false,
              source: new OSM(),
            }),
            new TileLayer({
              title: "FNSB Pictometry",
              type: "base",
              visible: false,
              source: new XYZ({
                url: "https://gisportal.fnsb.gov/image/rest/services/Imagery_2020_Pictometry_Fairbanks/MapServer/tile/{z}/{y}/{x}",
              }),
            }),
            new TileLayer({
              title: "USGS Topo",
              type: "base",
              visible: true,
              source: new XYZ({
                url: "https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}",
                attributions:
                  "Tiles © USGS The National Map: National Boundaries Dataset, 3DEP Elevation Program, " +
                  "Geographic Names Information System, National Hydrography Dataset, National Land Cover " +
                  "Database, National Structures Dataset, and National Transportation Dataset; USGS Global " +
                  "Ecosystems; U.S. Census Bureau TIGER/Line data; USFS Road Data; Natural Earth Data; " +
                  "U.S. Department of State Humanitarian Information Unit; and NOAA National Centers for " +
                  "Environmental Information, U.S. Coastal Relief Model. Data refreshed August, 2021.",
              }),
            }),
          ],
        }),
        new LayerGroup({
          title: "Data Layers",
          layers: [
            this.surface,
            this.awcStream,
            this.affi,
            this.fnsbRoads,
            this.flowline,
            this.chenaWatershed,
            this.mainCrossings,
          ],
        }),
      ],

      view: new View({
        zoom: 12,
        center: [-16444296, 9567464], // EPSG:3857
        constrainResolution: true,
      }),
    });

    const layerGroups = this.map.getLayers();

    layerGroups.forEach((lg) => {
      const title = lg.get("title");
      if (title === "Base maps") {
        const layers = lg.getLayers();
        layers.forEach((l) => {
          l.on("change:visible", (e) => {
            if (e.target.getVisible()) {
              localforage.setItem("base_layer", e.target.get("title"));
            }
          });
        });
      }
    });

    let centerX = null;
    let centerY = null;
    let zoom = null;
    let baseLayer = null;
    await Promise.all([
      localforage.getItem("center_x").then((v) => {
        if (v) {
          centerX = v;
        }
      }),
      localforage.getItem("center_y").then((v) => {
        if (v) {
          centerY = v;
        }
      }),
      localforage.getItem("zoom").then((v) => {
        if (v) {
          zoom = v;
        }
      }),
      localforage.getItem("base_layer").then((v) => {
        if (v) {
          baseLayer = v;
        }
      }),
    ]);

    if (centerX && centerY && zoom) {
      this.map.getView().animate({ center: [centerX, centerY], zoom: zoom });
    }
    if (baseLayer && baseLayer.length) {
      const layerGroups = this.map.getLayers();

      layerGroups.forEach((lg) => {
        const title = lg.get("title");
        if (title === "Base maps") {
          const layers = lg.getLayers();
          layers.forEach((l) => {
            const visible = l.getVisible();
            if (visible) {
              l.setVisible(false);
            }
          });
          layers.forEach((l) => {
            const layerTitle = l.get("title");
            if (layerTitle === baseLayer) {
              l.setVisible(true);
            }
          });
        }
      });
    }

    this.layerSwitcher = new LayerSwitcher();
    this.map.addControl(this.layerSwitcher);

    this.selectionLayer = new VectorTileLayer({
      map: this.map,
      renderMode: "vector",
      source: this.mainCrossings.getSource(),
      style: (f) => {
        if (f.getId() in this.selectedFeature) {
          if (f.getProperties().tvwa_row_number) {
            return this.riverHighlightStyle;
          } else {
            return this.streamHighlightStyle;
          }
        }
      },
    });

    this.map.on(
      "pointermove",
      function (e) {
        this.mainCrossings.getFeatures(e.pixel).then((f) => {
          if (!f.length) {
            this.selectedFeature = {};
            this.selectionLayer.changed();
            this.featureAttributes = {};

            return;
          }

          const feature = f[0];
          if (!feature) {
            return;
          }
          const fid = feature.getId();
          this.selectedFeature = {};
          this.selectedFeature[fid] = feature;
          this.selectionLayer.changed();

          const properties = feature.getProperties();
          this.featureAttributes = {
            ogcFid: fid,
            tvwaPriorityRank: properties.tvwa_priority_rank
              ? properties.tvwa_priority_rank
              : "unranked",
            roadName:
              properties.full_street && properties.full_street.length
                ? this.titleCap(properties.full_street)
                : null,
            riverName: properties.gnis_name,
            roadClass: this.firstCap(properties.roadclass),
            roadSurface:
              properties.surface && properties.surface.length
                ? this.firstCap(properties.surface)
                : null,
            public:
              properties.private && properties.private.length
                ? this.firstCap(properties.private)
                : null,
            maintenance:
              properties.maint_auth && properties.maint_auth.length
                ? this.formatMaintAuth(properties.maint_auth)
                : null,
            awcSpecies:
              properties.awc_species && properties.awc_species.length
                ? properties.awc_species
                : "N/A",
            culvertRatingCode:
              properties.adfg_culvert_rating_code &&
              properties.adfg_culvert_rating_code.length
                ? properties.adfg_culvert_rating_code
                : "N/A",
            tdmlCriteriaOfConcern:
              properties.tdml_criteria_of_concern &&
              properties.tdml_criteria_of_concern.length
                ? properties.tdml_criteria_of_concern
                : "none",
            crossingType:
              properties.crossing_type && properties.crossing_type.length
                ? properties.crossing_type
                : null,
            culvertId:
              properties.culvert_id && properties.culvert_id.length
                ? properties.culvert_id
                : null,
          };
        });
      }.bind(this)
    );
    this.map.on(
      "singleclick",
      function (e) {
        let popupContent = null;
        this.map
          .getFeaturesAtPixel(e.pixel, { hitTolerance: 5 })
          .forEach((f) => {
            const featureProperties = f.getProperties();
            const fid = f.getId();
            if (featureProperties.layer === "gis.surface") {
              popupContent = this.surfaceOverlay(fid, featureProperties);
            } else if (
              featureProperties.layer === "gis.crossing_all_attributes"
            ) {
              if (!popupContent) {
                popupContent = this.mainCrossingOverlay(fid, featureProperties);
              }
            } else if (featureProperties.layer === "gis.fnsb_road") {
              if (!popupContent) {
                popupContent = this.fnsbRoadsOverlay(fid, featureProperties);
              }
            } else if (featureProperties.layer === "gis.flowline") {
              if (!popupContent) {
                popupContent = this.flowlineOverlay(fid, featureProperties);
              }
            } else if (featureProperties.layer === "gis.affi") {
              if (!popupContent) {
                popupContent = this.affiPointsOverlay(fid, featureProperties);
              }
            } else if (featureProperties.layer === "gis.awc_stream_view") {
              if (!popupContent) {
                popupContent = this.awcStreamsOverlay(fid, featureProperties);
              }
            }
          });
        if (popupContent) {
          this.popupContent.innerHTML = popupContent;
          this.overlay.setPosition(e.coordinate);
        }
      }.bind(this)
    );
    this.map.on(
      "moveend",
      function (e) {
        const center = e.map.getView().getCenter();
        const zoom = e.map.getView().getZoom();

        localforage.setItem("center_x", center[0]);
        localforage.setItem("center_y", center[1]);
        localforage.setItem("zoom", zoom);

        const layerGroups = e.map.getLayers();

        layerGroups.forEach((lg) => {
          const title = lg.get("title");
          if (title === "Base maps") {
            const layers = lg.getLayers();
            layers.forEach((l) => {
              const layerTitle = l.get("title");
              const visible = l.getVisible();
              if (visible) {
                localforage.setItem("base_layer", layerTitle);
              }
            });
          }
        });
      }.bind(this)
    );
  },
  methods: {
    surfaceOverlay: function (fid, properties) {
      let overlayContent = `<p><b>Surface ${fid}</b>`;
      if (properties.gnis_name && properties.gnis_name.length) {
        overlayContent += `<br /><b>Stream name:</b> ${this.titleCap(
          properties.gnis_name
        )}`;
      }
      if (properties.full_street && properties.full_street.length) {
        overlayContent += `<br /><b>Road name:</b> ${this.titleCap(
          properties.full_street
        )}`;
      }
      if (properties.surface_type && properties.surface_type.length) {
        overlayContent += `<br /><b>Surface type:</b> ${this.titleCap(
          properties.surface_type
        )}`;
      }
      if (properties.slope && properties.slope.length) {
        overlayContent += `<br /><b>Slope:</b> ${this.titleCap(
          properties.slope
        )}`;
      }
      if (properties.veg_cover && properties.veg_cover.length) {
        overlayContent += `<br /><b>Vegetation cover:</b> ${this.titleCap(
          properties.veg_cover
        )}`;
      }
      if (properties.notes && properties.notes.length) {
        overlayContent += `<br /><b>Notes:</b> ${this.titleCap(
          properties.notes
        )}`;
      }
      overlayContent += `</p>`;
      return overlayContent;
    },
    mainCrossingOverlay: function (fid, properties) {
      let overlayContent = `<p><b>Crossing ${fid}</b>`;
      if (properties.gnis_name && properties.gnis_name.length) {
        overlayContent += `<br /><b>Stream name:</b> ${this.titleCap(
          properties.gnis_name
        )}`;
      }
      if (properties.full_street && properties.full_street.length) {
        overlayContent += `<br /><b>Road name:</b> ${this.titleCap(
          properties.full_street
        )}`;
      }
      if (properties.roadclass && properties.roadclass.length) {
        overlayContent += `<br /><b>Road class:</b> ${this.titleCap(
          properties.roadclass
        )}`;
      }
      if (properties.surface && properties.surface.length) {
        overlayContent += `<br /><b>Road surface:</b> ${this.titleCap(
          properties.surface
        )}`;
      }
      if (properties.maint_auth && properties.maint_auth.length) {
        overlayContent += `<br /><b>Maintenance authority:</b> ${this.titleCap(
          properties.maint_auth
        )}`;
      }
      if (properties.crossing_type && properties.crossing_type.length) {
        overlayContent += `<br /><b>Crossing Type:</b> ${this.titleCap(
          properties.crossing_type
        )}`;
      }
      if (properties.culvert_id && properties.culvert_id.length) {
        overlayContent += `<br /><b>Culvert IDs:</b> ${properties.culvert_id}`;
      }
      if (properties.awc_species && properties.awc_species.length) {
        overlayContent += `<br /><b>AWC Species:</b> ${properties.awc_species}`;
      }
      overlayContent += `</p>`;
      return overlayContent;
    },
    fnsbRoadsOverlay: function (fid, properties) {
      let overlayContent = `<p><b>Road ${fid}</b>`;
      if (properties.full_street && properties.full_street.length) {
        overlayContent += `<br /><b>Road name:</b> ${this.titleCap(
          properties.full_street
        )}`;
      }
      if (properties.roadclass && properties.roadclass.length) {
        overlayContent += `<br /><b>Road class:</b> ${this.titleCap(
          properties.roadclass
        )}`;
      }
      if (properties.surface && properties.surface.length) {
        overlayContent += `<br /><b>Road surface:</b> ${this.titleCap(
          properties.surface
        )}`;
      }
      if (properties.maint_auth && properties.maint_auth.length) {
        overlayContent += `<br /><b>Maintenance authority:</b> ${this.titleCap(
          properties.maint_auth
        )}`;
      }
      overlayContent += `</p>`;
      return overlayContent;
    },
    flowlineOverlay: function (fid, properties) {
      let overlayContent = `<p><b>Flowline ${fid}</b>`;
      if (properties.gnis_name && properties.gnis_name.length) {
        overlayContent += `<br /><b>River name:</b> ${this.titleCap(
          properties.gnis_name
        )}`;
      }
      if (properties.main_river === "All Streams") {
        overlayContent += "<br /><b>Class:</b> Minor stream";
      } else {
        overlayContent += "<br /><b>Class:</b> Main River";
      }
      overlayContent += `</p>`;
      return overlayContent;
    },
    affiPointsOverlay: function (fid, properties) {
      let overlayContent = `<p><b>AFFI Point ${fid}</b>`;
      if (properties.species && properties.species.length) {
        overlayContent += `<br /><b>Species:</b> ${properties.species}`;
      }
      if (properties.life_stage && properties.life_stage.length) {
        overlayContent += `<br /><b>Life stage:</b> ${properties.life_stage}`;
      }
      if (properties.life_history && properties.life_history.length) {
        overlayContent += `<br /><b>Life history:</b> ${properties.life_history}`;
      }
      overlayContent += `</p>`;
      return overlayContent;
    },
    awcStreamsOverlay: function (fid, properties) {
      let overlayContent = `<p><b>AWC Stream ${fid}</b>`;
      if (properties.stream_name && properties.stream_name.length) {
        overlayContent += `<br /><b>Stream name:</b> ${properties.stream_name}`;
      }
      if (properties.species && properties.species.length) {
        overlayContent += `<br /><b>Species:</b> ${properties.species}`;
      }
      if (properties.life_stage && properties.life_stage.length) {
        overlayContent += `<br /><b>Life stage:</b> ${properties.life_stage}`;
      }
      overlayContent += `</p>`;
      return overlayContent;
    },
    getFeatures: async function () {
      const date = new Date().toISOString();
      this.loadingData = true;
      const promises = await this.visibleFeatures.map(async (feature) => {
        return await axios.get(
          `nest_location_visit_architecture/nest_id/${feature.nest_id}/`
        );
      });
      const resolved = await Promise.all(promises);
      const nestLocationVisitArchitecture = resolved
        .map(
          (resource) =>
            resource.data.sort((a, b) =>
              ("" + b.visit_date).localeCompare(a.visit_date)
            )[0]
        )
        .flat();
      const select = [
        "nest_id",
        "build_species",
        "visit_date",
        "use_species",
        "nest_status",
        "tree_species",
        "latitude_nad83",
        "longitude_nad83",
      ];
      let csv = this.$papa.unparse(nestLocationVisitArchitecture, {
        columns: select,
      });
      this.loadingData = false;
      this.$papa.download(csv, `eagle_nests_${date}`);
    },
    getText: function (feature, resolution, maxResolution) {
      const properties = feature.getProperties();
      let text = this.titleCap(properties.full_street);
      if (resolution > maxResolution) {
        text = "";
      }

      return text;
    },
    createTextStyle: function (feature, resolution, maxResolution) {
      return new Text({
        placement: "line",
        font: "12px sans-serif",
        fill: new Fill({ color: "black" }),
        stroke: new Stroke({ color: "white", width: 3 }),
        text: this.getText(feature, resolution, maxResolution),
        maxAngle: 360,
      });
    },
    roadStyleFunction: function (feature, resolution) {
      return new Style({
        stroke: new Stroke({ width: 2, color: "#ff00ff99" }),
        fill: new Fill({ color: "#ff00ff33" }),
        text: this.createTextStyle(feature, resolution, this.maxResolutionText),
      });
    },
    firstCap: function (s) {
      if (s && s.length) {
        return s[0].toUpperCase() + s.substring(1).toLowerCase();
      } else {
        return s;
      }
    },
    titleCap: function (s) {
      if (s !== undefined && s !== null && s.length > 0) {
        return s
          .split("/ +/")
          .map((w) => {
            return w[0].toUpperCase() + w.substring(1).toLowerCase();
          })
          .join(" ");
      } else {
        return s;
      }
    },
    formatMaintAuth: function (s) {
      if (s && s.length) {
        if (
          s === "STATE OF ALASKA" ||
          s === "CITY OF FAIRBANKS" ||
          s === "CITY OF NORTH POLE"
        ) {
          return this.titleCap(s);
        } else {
          return `${this.titleCap(s)} Service Area`;
        }
      } else {
        return s;
      }
    },
  },
};
</script>

<style>
.full {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.fill {
  flex: 1 1 auto;
}

.bottom {
  flex: 0 1 0 !important;
}

.layer-switcher {
  top: 0.5em;
  right: 0.5em;
}

.layer-switcher .panel {
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(64, 64, 64, 0.7);
}

.layer-switcher ul {
  list-style: none;
  margin: 1em 0.4em;
  padding-left: 0;
}

.layer-switcher ul ul {
  padding-left: 0.6em;
  margin: 0.1em 0 0 0;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-popup-closer:after {
  content: "✖";
}
</style>
