<template>
  <crossing-map />
</template>

<script>
import CrossingMap from "../components/CrossingMap";

export default {
  name: "Home",

  components: {
    CrossingMap,
  },
};
</script>
